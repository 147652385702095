var ElcServiceBus;
var ServiceBusTopics;
var site = window.site || {};

ElcServiceBus || window.ElcServiceBus;
ServiceBusTopics = ServiceBusTopics || window.ServiceBusTopics;

(function ($) {
  Drupal.behaviors.productContentCardV1 = {
    productHTML: '',
    attach: function () {
      var self = this;
      var $modules = $('.js-product-content-card', document);

      $modules.each(function () {
        var $module = $(this);

        self.getDom($module);
        self.getProps($module);
        self.setInits($module);
        self.setListeners($module);
      });
    },
    getDom: function ($module) {
      $module.nodes = {};
      $module.nodes.parent = $module.parent();
      $module.nodes.products = $('.js-product-content-card-product', $module);
      $module.nodes.cta = $('.js-product-content-card-link', $module);
      $module.nodes.ctaOverlay;
      $module.nodes.atb;
    },
    getProps: function ($module) {
      var ctaTranslation;

      $module.props = {};
      $module.props.productCode = $module.data('productCode');
      $module.props.skuIds = $module.data('skuId');
      $module.props.ctaAsOverlay = $module.data('ctaAsOverlay');
      $module.props.ctaAsAtb = $module.data('ctaAsAtb');
      $module.props.ctaFirstProduct = $module.data('ctaFirstProduct');
      $module.props.hidePrice = $module.data('hidePrice');
      $module.props.hideQuantity = $module.data('hideQuantity');
      $module.props.hideImage = $module.data('hideImage');
      $module.props.cardDataDriven = $module.data('cardDataDriven');
      ctaTranslation = $module.props.ctaAsAtb ?
        site.translations.elc_cart.add_to_bag :
        site.translations.elc_product.shop_now;
      $module.props.ctaTheme = {
        text: $module.data('ctaText') ? $module.data('ctaText') : ctaTranslation,
        colorVals: $module.data('ctaColorVals') || false,
        bgColor: $module.data('ctaBgColor') || '',
        borderColor: $module.data('ctaBorderColor') || '',
        textColor: $module.data('ctaTextColor') || '',
        classNames: $module.data('ctaClassnames') || 'cta-rouded-stroke--blue'
      };
    },
    renderProduct: function ($module, renderData) {
      var self = this;
      var template;
      var rendered;

      if ($module.props.cardDataDriven) {
        template = $('script.inline-template[path="/templates/editorial/product_content_card_v1/_partials/product-content-card-partial.tmpl"]').html();
        rendered = Mustache.render(template, renderData);
        $module.nodes.products.append($(rendered));
      }

      $module.nodes.atb = $(
        '.js-product-content-card-atb',
        $module.nodes.products
      );

      self.setPartialListener($module);
      self.setAtbListener($module);
    },
    setPartialListener: function ($module) {
      var self = this;

      if ($module.props.ctaAsOverlay) {
        $module.nodes.ctaOverlay = $('.js-product-content-card-overlay', $module);
        $module.nodes.ctaOverlay.off('click.cta').on('click.cta', function (e) {
          e.preventDefault();
          self.triggerOverlay($module);
        });
      }
    },
    getCtaConfig: function ($module, sku) {
      return {
        is_overlay: $module.props.ctaAsOverlay,
        is_atb: $module.props.ctaAsAtb,
        href: sku?.['product_url'] || '#',
        text: $module.props.ctaTheme.text,
        classname: $module.props.ctaTheme.classNames,
        open_new_window: false,
        color_vals: $module.props.ctaTheme.colorVals,
        bgcolor: $module.props.ctaTheme.bgColor,
        textcolor: $module.props.ctaTheme.textColor,
        bordercolor: $module.props.ctaTheme.borderColor
      };
    },
    parseProductData: function ($module, data) {
      var self = this;
      var renderData = {};
      var sku;
      var hidePrice;
      var hideQuantity;
      var hideImage;

      sku = data?.[1]?.[$module.props.productCode];
      if (!sku) {
        return;
      }

      renderData['display_name'] = sku?.['display_name'];
      renderData['sub_display_name'] = sku?.['subDisplayName'];

      hidePrice = $module.props.hidePrice === 1;
      hideQuantity = $module.props.hideQuantity === 1;
      hideImage = $module.props.hideImage === 1;

      if (!hidePrice) {
        renderData['price'] = sku?.['prices'][0]?.['priceFormatted'] || '';
      }

      if (!hideImage) {
        renderData['media'] = sku?.['media']['large'][0]?.['src'] || '';
      }

      if (!hideQuantity) {
        renderData['size'] = sku?.['sizes'][0]?.['value'] || '';
      }

      renderData['show_price'] = !hidePrice;
      renderData['show_image'] = !hideImage;
      renderData['show_quantity'] = !hideQuantity;
      renderData['render_partial'] = $module.props?.cardDataDriven;
      renderData['ctaConfig'] = self.getCtaConfig($module, sku);

      self.renderProduct($module, renderData);
    },
    getProductData: function ($module) {
      var self = this;
      var promises = {};
      var productServiceIsGraphQL =
        Drupal?.settings?.product_service === 'graphql';

      try {
        if (productServiceIsGraphQL) {
          promises.skus = ElcServiceBus.query(
            ServiceBusTopics.queries.GET_SKUS,
            [$module.props.productCode]
          );
        }
        // Requires the promises.products to return data even if not defined.
        Promise.all([promises.products, promises.skus]).then((data) => {
          if (data?.[1] && $module.props.productCode in data[1]) {
            self.parseProductData($module, data);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    setInits: function ($module) {
      var self = this;

      $module.nodes.parent.addClass('content-card-root');
      if ($module.props.productCode) {
        self.getProductData($module);
      }
    },
    triggerOverlay: function ($module) {
      $.event.trigger({
        type: 'basic_modal_formatter:toggle_overlay',
        payload: {
          $el: $module
        }
      });
    },
    setListeners: function ($module) {
      var self = this;
      var ctaLabel;
      var eventLabel;

      if ($module.props.ctaAsOverlay) {
        $module.nodes.cta.off('click.overlay').on('click.overlay', function (e) {
          e.preventDefault();
          self.triggerOverlay($module);
          if (
            $('body').hasClass(
              'section-paddington-limited-edition-collection'
            ) &&
            'track' in window.site &&
            'evtLink' in window.site.track
          ) {
            ctaLabel = $module.props.ctaTheme.text;
            eventLabel = '';

            if (ctaLabel === 'Get Stuck In') {
              eventLabel = 'Banner Clicks | Project P | Recipe';
            } else if (ctaLabel === 'Shop Now') {
              eventLabel =
                'Banner Clicks | Project P | Product Grid | Shop Now';
            }

            site.track.evtLink({
              event_name: 'content-module-click',
              event_category: 'project-p modules clicks',
              event_action: 'Module Clicks',
              event_label: eventLabel
            });
          }
        });
      }
      if ($module.props.ctaAsAtb) {
        $module.nodes.atb = $('.js-product-content-card-atb', $module);
        self.setAtbListener($module);
      }
    },
    setAtbListener: function ($module) {
      var payload = {};

      payload.quantity = 1;
      payload.replenishment = false;
      if ($module.nodes.atb.length > 0) {
        $module.nodes.atb.once().on('click', function (e) {
          e.preventDefault();
          $(document).trigger('perlgem.cart.addItem', [
            $module.props.skuIds.toString(),
            payload
          ]);
        });
      }
    }
  };
})(jQuery);
